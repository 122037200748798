<script lang="ts" setup>
import AppContent from '@/components/app-content.vue'
import AppYoutube from '@/components/app-youtube.vue'
import { useIntervalFn } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHeadTitle } from '../../src/lib/use-app-head-holtwick'
import imgDEScreen1 from './_assets/de/screen-1-hp.png'
import imgDEScreen12x from './_assets/de/screen-1-hp@2x.png'
import imgDEScreen2 from './_assets/de/screen-2-hp.png'
import imgDEScreen22x from './_assets/de/screen-2-hp@2x.png'
import imgDEScreen3 from './_assets/de/screen-3-hp.png'
import imgDEScreen32x from './_assets/de/screen-3-hp@2x.png'
import imgENScreen1 from './_assets/en/screen-1-hp.png'
import imgENScreen12x from './_assets/en/screen-1-hp@2x.png'
import imgENScreen2 from './_assets/en/screen-2-hp.png'
import imgENScreen22x from './_assets/en/screen-2-hp@2x.png'
import imgENScreen3 from './_assets/en/screen-3-hp.png'
import imgENScreen32x from './_assets/en/screen-3-hp@2x.png'
import imgAppgefahren from './_assets/press/appgefahren@2x.png'
import imgIfun from './_assets/press/ifun@2x.png'
import imgMacandi from './_assets/press/macandi@2x.png'
import imgMaclife from './_assets/press/maclife@2x.png'
import youtube from './_assets/youtube.png'

import './app-homepage.styl'

const { locale, t } = useI18n()

const next = ref(0)

useIntervalFn(() => next.value = next.value >= 2 ? 0 : next.value + 1, 5e3)

const screens = computed(() => (locale.value === 'de'
  ? [
      [imgDEScreen1, imgDEScreen12x],
      [imgDEScreen2, imgDEScreen22x],
      [imgDEScreen3, imgDEScreen32x],
    ]
  : [
      [imgENScreen1, imgENScreen12x],
      [imgENScreen2, imgENScreen22x],
      [imgENScreen3, imgENScreen32x],
    ]))

useHeadTitle(t('homepage.title'))
/* useHead({
  link: [
    { rel: 'preload', as: 'image', href: imgDEScreen1 },
    { rel: 'preload', as: 'image', href: imgDEScreen2 },
    { rel: 'preload', as: 'image', href: imgDEScreen3 },
    { rel: 'preload', as: 'image', href: imgENScreen1 },
    { rel: 'preload', as: 'image', href: imgENScreen2 },
    { rel: 'preload', as: 'image', href: imgENScreen3 },
  ],
}) */
</script>

<template>
  <main class="app-homepage">
    <div class="hero">
      <div class="grid-container">
        <div class="content">
          <div class="content-text">
            <div class="headlines">
              <h1 class="clip">
                {{ $t("homepage.title") }}
              </h1>
              <p class="slogan">
                {{ $t("homepage.slogan") }}
              </p>
            </div>
            <div class="actions">
              <a class="button" href="/latest">{{ $t("homepage.download") }}</a>
              <!-- <router-link :to="`/${$i18n.locale}/help`" >
                {{ $t("homepage.buttonMore") }}
              </router-link> -->
              <!-- <router-link :to="`/${$i18n.locale}/buy`" class="button">
                Buy now
              </router-link> -->
            </div>
            <div class="configuration">
              {{ $t("homepage.requirements") }}
            </div>
          </div>
          <ClientOnly>
            <div class="content-show">
              <div class="slideshow">
                <Transition name="slideshow" mode="out-in">
                  <template v-if="next === 0">
                    <img :src="screens[next][1]" :alt="$t('homepage.screenshot1')" height="432" width="690">
                  </template>
                  <template v-else-if="next === 1">
                    <img :src="screens[next][1]" :alt="$t('homepage.screenshot2')" height="432" width="690">
                  </template>
                  <template v-else-if="next === 2">
                    <img :src="screens[next][1]" :alt="$t('homepage.screenshot3')" height="432" width="690">
                  </template>
                </Transition>
              </div>
            </div>
          </ClientOnly>
        </div>
      </div>
    </div>

    <div class="app-content prose">
      <Suspense>
        <AppContent :component="$route.meta.comp" />
      </Suspense>
    </div>

    <div class="app-homepage-description">
      <div class="app-content prose _grid_2up">
        <div v-if="$i18n.locale === 'de'">
          <h2>Wichtigste Merkmale</h2>
          <ul>
            <li>Einfaches Sammeln von Eingangsrechnungen, Ausgangsrechnungen, Quittungen und sonstigen Belegen</li>
            <li>Selbständiges Erkennen der Inhalte wie Betrag, Datum, Steuern und Bankdaten</li>
            <li>Selbstlernende Zuordnung von Dokumenten zu Kontakten und Kategorien</li>
            <li>Automatische Umrechnung von Fremdwährungen</li>
            <li>Unterstützung von Scannern und Dokumentenscannern inkl. ScanSnap</li>
            <li>Eingebaute Texterkennung (OCR)</li>
            <li>Direktes Bezahlen von Eingangsrechnungen aus der App heraus</li>
            <li>Erfassung von Umsatzsteuerwerten und Vorbereitung für die Steuererklärung/ Voranmeldung</li>
            <li>Flexible Exporte und Berichte</li>
            <li>Mächtige Suche und Filter</li>
            <li>Umfangreiche Auswertungen</li>
            <li>Schutz der Privatsphäre durch lokale Speicherung der Daten</li>
            <li>Ablage der Dokumente im Original und als PDF</li>
            <li>Flexible Einstellungen</li>
            <li>Mehrsprachig</li>
          </ul>
        </div>
        <div v-if="$i18n.locale === 'en'">
          <h2>Most important features</h2>
          <ul>
            <li>Autonomous recognition of contents such as amount, date, taxes and bank data</li>
            <li>Self-learning assignment of documents to contacts and categories</li>
            <li>Automatic conversion of foreign currencies</li>
            <li>Support for scanners and document scanners</li>
            <li>Built-in text recognition (OCR)</li>
            <li>Direct payment of invoices from within the app</li>
            <li>Entry of sales tax values and preparation for tax declarations</li>
            <li>Flexible exports and reports</li>
            <li>Powerful search and filters</li>
            <li>Extensive data evaluations</li>
            <li>Protection of privacy through local storage of data</li>
            <li>Storage of documents in original and PDF format</li>
            <li>Flexible settings</li>
            <li>Multilingual</li>
          </ul>
        </div>

        <div v-if="$i18n.locale === 'de'">
          <h2>Umfangreiche Schnittstellen</h2>

          <div class="ho-subitem">
            Rechnungswesen und Steuern
          </div>
          <ul>
            <li><a href="/de/help#grandtotal">GrandTotal</a></li>
            <li><a href="/de/help#umsatzapp">Umsatz Programm</a></li>
            <li><a href="/de/help#steuerapp">Steuererklärung.app</a></li>
          </ul>

          <div class="ho-subitem">
            Online Banking
          </div>
          <ul>
            <li><a href="/de/help#moneymoney">MoneyMoney</a></li>
            <li><a href="/de/help#bankx">Bank X Professional</a></li>
            <li><a href="/de/help#ifinance">iFinance</a></li>
            <li><a href="/de/help#sepa">SEPA</a></li>
          </ul>

          <div class="ho-subitem">
            E-Mail
          </div>
          <ul>
            <li><a href="/de/help#applemail">Apple Mail</a></li>
            <li><a href="/de/help#mailmate">MailMate</a></li>
            <li><a href="/de/help#mailplane">Mailplane</a></li>
            <li>Viele mehr durch <a href="/de/help#dnd">Drag-and-Drop</a></li>
          </ul>

          <div class="ho-subitem">
            Export
          </div>
          <ul>
            <li>Microsoft Excel</li>
            <li>Apple Numbers</li>
            <li><a href="/de/help#datev">DATEV</a></li>
          </ul>
        </div>
        <div v-if="$i18n.locale === 'en'">
          <h2>Extensive third-party support</h2>

          <div class="ho-subitem">
            Invoices
          </div>
          <ul>
            <li><a href="/en/help#grandtotal">GrandTotal</a></li>
          <!-- <li><a href="/en/help#umsatzapp">Umsatz Programm</a></li> -->
          <!-- <li><a href="/en/help#steuerapp">Steuererklärung.app</a></li> -->
          </ul>

          <div class="ho-subitem">
            Banking
          </div>
          <ul>
            <li><a href="/en/help#moneymoney">MoneyMoney</a></li>
            <li><a href="/en/help#bankx">Bank X Professional</a></li>
            <li><a href="/en/help#ifinance">iFinance</a></li>
            <li><a href="/en/help#sepa">SEPA</a></li>
          </ul>

          <div class="ho-subitem">
            Email
          </div>
          <ul>
            <li><a href="/en/help#applemail">Apple Mail</a></li>
            <li><a href="/en/help#mailmate">MailMate</a></li>
            <li><a href="/en/help#mailplane">Mailplane</a></li>
            <li>Many more via <a href="/en/help#dnd">drag-and-drop</a></li>
          </ul>

          <div class="ho-subitem">
            Export
          </div>
          <ul>
            <li>Microsoft Excel</li>
            <li>Apple Numbers</li>
          <!-- <li><a href="/en/help#datev">DATEV</a></li> -->
          </ul>
        </div>
      </div>
    </div>

    <div v-if="$i18n.locale === 'de'" class="app-homepage-reference app-content prose">
      <div class="_grid_4or2">
        <div>
          <div class="quote-author">
            <a href="https://www.heise.de/select/mac-and-i/2017/3/1497021106850620">
              <img alt="mac &amp; i" class="_reference_icon" :src="imgMacandi" width="165" height="40">
            </a>
          </div>
          <p>
            Receipts ist ein praktisches und intuitiv bedienbares Programm, mit dem Selbstständige den
            Geldfluss im Blick behalten; zudem erleichtert es Spesenabrechnungen und die Vorbereitung der
            Steuererklärung.
          </p>
        </div>
        <div>
          <div class="quote-author">
            <a href="https://www.ifun.de/suche/receipts-app">
              <img alt="ifun.de" class="_reference_icon" :src="imgIfun" height="40" width="40">
            </a>
          </div>
          <p>Unser Eindruck:<br> Die Receipts-Verantwortlichen leisten hervorragende Arbeit. Neue Aktualisierungen kommen regelmäßig mit neuen Funktionen.</p>
        </div>
        <div>
          <div class="quote-author">
            <a href="http://www.maclife.de/ratgeber/sind-besten-mac-apps-unternehmer-10091898.html">
              <img alt="macLife" class="_reference_icon" :src="imgMaclife" height="40" width="157">
            </a>
          </div>
          <p>
            Um Ordnung in die Zettelwirtschaft zu bringen, empfiehlt sich der Einsatz einer Belegverwaltung.
            Receipts sorgt mit cleverer Automatisierung für viel Zeitersparnis.
          </p>
        </div>
        <div>
          <div class="quote-author">
            <a
              href="http://www.appgefahren.de/receipts-automatisierte-belegerfassung-hilft-bei-steuererklaerung-und-rechnungsverwaltung-191686.html"
            >
              <img alt="appgefahren" class="_reference_icon" :src="imgAppgefahren" height="40" width="47">
            </a>
          </div>
          <p>
            Das Jahr ist beendet, und damit beginnt für Privatpersonen, Freiberufler und Unternehmen die Zeit
            der steuerlichen Aufarbeitung. Mit Receipts für den Mac gibt es eine praktische Hilfe.
          </p>
        </div>
      </div>
    </div>
    <div class="app-content prose">
      <AppYoutube
        src="https://www.youtube.com/embed/0VgMdm1xQG0"
        :src-preview="youtube"
      />
    </div>
  </main>
  <div class="oui-invisible">
    <img width="1" height="1" :src="screens[0][1]" :alt="$t('homepage.screenshot1')">
    <img width="1" height="1" :src="screens[1][1]" :alt="$t('homepage.screenshot2')">
    <img width="1" height="1" :src="screens[2][1]" :alt="$t('homepage.screenshot3')">
  </div>
</template>
